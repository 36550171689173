import { useParams } from 'react-router-dom';
import Map, { Marker, FullscreenControl } from 'react-map-gl';
import { useCallback, useEffect, useState } from 'react';
import { Avatar, Button, IconButton } from '@mui/material';
import axios from 'axios';
import { UserInfo } from '../../interfaces/ProfileInfo'
import { PinIcon, PinPoco, PinAbb, PinPro } from '../../assets/icons/Index';
import StylesControl from '../../components/specific/map/StylesControl';
import { MapStyle } from '../../types/map';
import InfoMarkerModal from '../../components/specific/modal/InfoMarkerModal';
import { MarkerData } from '../../types/markerData';
import { useTranslation } from 'react-i18next';
import DefaultMarkerModal from '../../components/specific/modal/DefaultMarkerModal';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const url = process.env.REACT_APP_BASE_URL;
const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const Profile = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const [userInfo, setUserInfo] = useState<UserInfo>({
    first_name: '',
    profile_picture: '',
    email: ''
  });
  const [mapStyle, setMapStyle] = useState<string>("mapbox://styles/mapbox/light-v9");
  const [markers, setMarkers] = useState<MarkerData[]>([]);
  const [popupInfo, setPopupInfo] = useState<MarkerData | null>(null);
  const [email, setEmail] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDefaultMarkerModalOpen, setIsDefaultMarkerModalOpen] = useState<boolean>(false);
  const [residencePlace, setResidencePlace] = useState<string>('');
  const [publicResidence, setPublicResidence] = useState<boolean>(false);
  const [publicPhoneNumber, setPublicPhoneNumber] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [residenceCoordinates, setResidenceCoordinates] = useState<{ longitude: number; latitude: number } | null>(null);
  const [initialViewState, setInitialViewState] = useState({
    longitude: 12.646361,
    latitude: 42.504154,
    zoom: 4,
  });

  const fetchMarkers = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/pins/user/${userId}`);
      setMarkers(response.data);
    } catch (error) {
      console.error('Error fetching markers:', error);
    }
  }, [userId]);

  useEffect(() => {
    const fetchUserInfo = () => {
      axios
        .get(`${url}/users/${userId}/base_info/`)
        .then((response) => {
          const userData = response.data;
          setUserInfo({
            first_name: userData.first_name || '',
            profile_picture: userData.profile_picture || '/placeholder.svg'
          })
          const residence = userData.residence;
          const isPublic = userData.public_residence;
          const phone = userData.phone_number;
          const isPhonePublic = userData.public_phone_number;
          const email = userData.email;

          setPublicResidence(isPublic ?? false);
          setPublicPhoneNumber(isPhonePublic ?? false);
          setPhoneNumber(phone ?? null);
          setEmail(email ?? '');

          if (residence && isPublic) {
            setResidencePlace(residence);
            axios
              .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(residence)}.json`, {
                params: {
                  access_token: TOKEN,
                  limit: 1,
                },
              })
              .then((geoResponse) => {
                const features = geoResponse.data.features;
                if (features && features.length > 0) {
                  const [longitude, latitude] = features[0].center;
                  setResidenceCoordinates({ longitude, latitude });
                  setInitialViewState({
                    longitude,
                    latitude,
                    zoom: 4,
                  });
                } else {
                  console.error('Nessun risultato trovato per la geocodifica della residenza.');
                }
              })
              .catch((err) => {
                console.error('Errore nella geocodifica:', err);
              });
          }
        })
        .catch((err) => {
          console.error('Errore nel recupero dei dati utente:', err);
        });
    };
    fetchMarkers();
    fetchUserInfo();

    const handleProfilePictureUpdated = () => {
      fetchUserInfo();
    };

    window.addEventListener('profilePictureUpdated', handleProfilePictureUpdated);

    return () => {
      window.removeEventListener('profilePictureUpdated', handleProfilePictureUpdated);
    };
  }, [fetchMarkers, userId]);

  const handleSubscribeClick = () => {
    window.location.href = "https://www.pinnalo.it";
  };

  const handleStyleChange = useCallback((style: MapStyle) => {
    setMapStyle(style.styleUrl);
  }, []);

  const handleMarkerClick = useCallback((marker: MarkerData) => {
    setPopupInfo(marker);
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setPopupInfo(null);
  };

  const handleResidenceMarkerClick = () => {
    setIsDefaultMarkerModalOpen(true);
  };

  const emailSubject = t('profile:email_subject');
  const emailBody = t('profile:email_body');
  const handleEmail = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;

  return (
    <div className='w-full h-lvh flex flex-col gap-4 p-4 md:p-6'>
      <div className='w-full flex items-center flex-wrap justify-between gap-4 md:flex-row md:items-center md:justify-between'>
        {/* Sezione sinistra (Avatar e Titoli su desktop, solo Avatar su mobile) */}
        <div className='flex items-center gap-5'>
          {/* Avatar */}
          <Avatar
            alt={userInfo.first_name}
            src={userInfo.profile_picture || 'placeholder.svg'}
            sx={{ width: 60, height: 60 }}
          />

          {/* Titoli (visibili solo su desktop) */}
          <div className='hidden md:block'>
            <h1>
              {t('profile:hi')}
              <strong className='text-primary'>{userInfo.first_name}</strong> {t('profile:my_map')} :&#41;
            </h1>
            <h2 className='text-text-primary'>{t('profile:map_description')}</h2>
          </div>
        </div>

        {/* Sezione destra (Bottoni) */}
        <div className='flex items-center gap-6 md:mt-0 md:gap-8'>
          {/* Pulsante Telefono */}
          {publicPhoneNumber && phoneNumber && (
            <IconButton
              component='a'
              href={`https://wa.me/39${phoneNumber}`}
              target='_blank'
              className='text-white'
              sx={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                backgroundColor: '#E9495A',
                boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
                '&:hover': {
                  backgroundColor: '#E6263B',
                }
              }}
            >
              <WhatsAppIcon />
            </IconButton>
          )}

          {/* Pulsante Email */}
          <IconButton
            component='a'
            href={handleEmail}
            target='_blank'
            className='text-white'
            sx={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              backgroundColor: '#E9495A',
              boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
              '&:hover': {
                backgroundColor: '#E6263B',
              }
            }}
          >
            <EmailIcon />
          </IconButton>
        </div>

        {/* Titoli (visibili solo su mobile) */}
        <div className='block md:hidden w-full text-left text-sm'>
          <h1>
            {t('profile:hi')}
            <strong className='text-primary'>{userInfo.first_name}</strong> {t('profile:my_map')} :&#41;
          </h1>
          <h2 className='text-text-primary'>{t('profile:map_description')}</h2>
        </div>
      </div>

      <Map
        initialViewState={initialViewState}
        style={{ flex: 1, borderRadius: '1rem', height: '100%' }}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        mapStyle={mapStyle}
      >
        <FullscreenControl position='top-right' />
        <div style={{ position: 'absolute', top: 12, left: 12, zIndex: 1 }}>
          <StylesControl onChange={handleStyleChange} />
        </div>

        {markers.map((marker, index) => {
          let PinComponent;
          if (marker.knowledge_level === 'Poco') {
            PinComponent = PinPoco;
          } else if (marker.knowledge_level === 'Abbastanza') {
            PinComponent = PinAbb;
          } else if (marker.knowledge_level === 'Molto') {
            PinComponent = PinIcon;
          } else {
            PinComponent = PinPro;
          }

          return (
            <Marker
              key={index}
              longitude={marker.longitude}
              latitude={marker.latitude}
              onClick={() => handleMarkerClick(marker)}
            >
              <PinComponent className='' />
            </Marker>
          )
        })}

        {residenceCoordinates && publicResidence && (
          <Marker
            longitude={residenceCoordinates.longitude}
            latitude={residenceCoordinates.latitude}
            onClick={(e) => {
              e.originalEvent.stopPropagation();
              handleResidenceMarkerClick();
            }}
          >
            <PinPro className="" />
          </Marker>
        )}
      </Map>

      <div className='w-full flex items-center justify-center mt-2'>
        {/* Subscribe Button */}
        <Button onClick={handleSubscribeClick} variant='contained' className='bg-primary hover:bg-primary-hover w-full md:w-80 md:mt-0'>
          {t('profile:create_map')}
        </Button>
      </div>

      {isDefaultMarkerModalOpen && (
        <DefaultMarkerModal
          open={isDefaultMarkerModalOpen}
          onClose={() => setIsDefaultMarkerModalOpen(false)}
          place={residencePlace}
          text={t('pinMap:profile_marker_message')}
        />
      )}

      {popupInfo && (
        <InfoMarkerModal
          marker={popupInfo}
          open={isModalOpen}
          onClose={handleCloseModal}
          hidePrivateContent={true}
        />
      )}
    </div>
  );
};

export default Profile;