import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

import it from './it.json';
import en from './en.json';

export const resources = {
  it: it,
  en: en
};

export const APP_LANGUAGES = [
  { label: "Italiano", value: "it", json: it },
  { label: "English", value: "en", json: en }
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    ns: [],
    defaultNS: '',
    keySeparator: false,
    lng: 'it',
    fallbackLng: 'it',
    debug: false,
    react: {
      useSuspense: false,
    },
    supportedLngs: APP_LANGUAGES.map((language) => language.value),
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: "i18nextLng",
    },
  });

export default i18n;