import Map, { FullscreenControl, GeolocateControl, Marker, MapRef } from 'react-map-gl';
import { useRef, useState, useCallback, useEffect } from 'react';
import { MarkerData } from '../../../types/markerData';
import MarkerModal from '../modal/MarkerModal';
import './mapBox.css'
import StylesControl from './StylesControl';
import { MapStyle } from '../../../types/map';
import { PinIcon, PinPoco, PinAbb, PinPro } from '../../../assets/icons/Index';
import { useMarkers } from '../../../hooks/useMarker';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoMarkerModal from '../modal/InfoMarkerModal';
import axios from 'axios';
import { toast } from 'react-toastify';
import DefaultMarkerModal from '../modal/DefaultMarkerModal';
import EditMarkerModal from '../modal/EditMarkerModal';
import dayjs from 'dayjs';

const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const url = process.env.REACT_APP_BASE_URL;

const MapBox = () => {
  const { markers, fetchMarkers } = useMarkers();
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [newMarker, setNewMarker] = useState<MarkerData | null>(null);
  const [selectedMarker, setSelectedMarker] = useState<MarkerData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isNewMarkerModal, setIsNewMarkerModal] = useState<boolean>(false);
  const [isDefaultMarkerModalOpen, setIsDefaultMarkerModalOpen] = useState<boolean>(false);
  const [isEditMarkerModalOpen, setIsEditMarkerModalOpen] = useState<boolean>(false);
  const [residencePlace, setResidencePlace] = useState<string>('');
  const [publicResidence, setPublicResidence] = useState<boolean>(false);
  const [mapStyle, setMapStyle] = useState<string>("mapbox://styles/mapbox/light-v9");
  const mapRef = useRef<MapRef>(null);

  const [residenceCoordinates, setResidenceCoordinates] = useState<{ longitude: number; latitude: number } | null>(null);
  const [initialViewState, setInitialViewState] = useState({
    longitude: 12.646361,
    latitude: 42.504154,
    zoom: 4,
  });

  const handleStyleChange = useCallback((style: MapStyle) => {
    setMapStyle(style.styleUrl);
  }, []);

  useEffect(() => {
    const authToken = sessionStorage.getItem('authToken');

    if (authToken) {
      axios
        .get(`${url}/users/auth/profile/`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          const userData = response.data;
          const residence = userData.residence;
          const isPublic = userData.public_residence;

          setPublicResidence(isPublic ?? false);

          if (residence && isPublic) {
            setResidencePlace(residence);
            axios
              .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(residence)}.json`, {
                params: {
                  access_token: TOKEN,
                  limit: 1,
                },
              })
              .then((geoResponse) => {
                const features = geoResponse.data.features;
                if (features && features.length > 0) {
                  const [longitude, latitude] = features[0].center;
                  setResidenceCoordinates({ longitude, latitude });
                  setInitialViewState({
                    longitude,
                    latitude,
                    zoom: 4,
                  });
                } else {
                  console.error('Nessun risultato trovato per la geocodifica della residenza.');
                }
              })
              .catch((err) => {
                console.error('Errore nella geocodifica:', err);
              });
          }
        })
        .catch((err) => {
          console.error('Errore nel recupero dei dati utente:', err);
        });
    } else {
      console.error('ID utente o token di autenticazione non trovati nello storage della sessione.');
    }
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (mapRef.current) {
        const mapElement = mapRef.current.getMap().getContainer();
        if (document.fullscreenElement === mapElement) {
          setIsFullscreen(true);
        } else {
          setIsFullscreen(false);
        }
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const handleResidenceMarkerClick = () => {
    setIsDefaultMarkerModalOpen(true);
  };

  const handleMapClick = useCallback((event: any) => {
    const { lngLat } = event;
    setNewMarker({
      longitude: lngLat.lng,
      latitude: lngLat.lat,
      place: '',
      times_visited: 0,
      knowledge_level: 'Poco',
      date_last_visit: dayjs().format('YYYY-MM'),
      pin_type: [],
      photo_link: '',
      content: '',
      content_private: true
    });
    setIsNewMarkerModal(true);
    setIsModalOpen(true);
  }, []);

  const handleFabClick = () => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      setNewMarker({
        longitude: center.lng,
        latitude: center.lat,
        place: '',
        times_visited: 0,
        knowledge_level: 'Poco',
        date_last_visit: dayjs().format('YYYY-MM'),
        pin_type: [],
        photo_link: '',
        content: '',
        content_private: true,
      });
      setIsNewMarkerModal(true);
      setIsModalOpen(true);
    }
  };

  const handleMarkerSubmit = useCallback(() => {
    setIsModalOpen(false);
    if (newMarker && mapRef.current) {
      mapRef.current.flyTo({
        center: [newMarker.longitude, newMarker.latitude],
        zoom: 12,
        essential: true,
      });
    }
    setNewMarker(null);
    setIsNewMarkerModal(false);
    fetchMarkers();
  }, [fetchMarkers, newMarker]);

  const handleMarkerClick = useCallback((marker: MarkerData) => {
    setSelectedMarker(marker);
    setIsNewMarkerModal(false);
    setIsModalOpen(true);
  }, []);

  return (
    <>
      <Map
        ref={mapRef}
        mapboxAccessToken={TOKEN}
        initialViewState={initialViewState}
        style={{
          width: '100%',
          height: '100vh',
          maxWidth: '100%',
          maxHeight: '100vh',
          borderRadius: '1rem',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          margin: '0.5rem auto'
        }}
        mapStyle={mapStyle}
        onClick={handleMapClick}
      >
        <GeolocateControl position="top-right" />
        <FullscreenControl position="top-right" />
        <div style={{ position: 'absolute', top: 12, left: 12, zIndex: 1 }}>
          <StylesControl onChange={handleStyleChange} />
        </div>

        {markers.map((marker, index) => {
          let PinComponent;
          if (marker.knowledge_level === 'Poco') {
            PinComponent = PinPoco;
          } else if (marker.knowledge_level === 'Abbastanza') {
            PinComponent = PinAbb;
          } else if (marker.knowledge_level === 'Molto') {
            PinComponent = PinIcon;
          } else {
            PinComponent = PinPro;
          }

          return (
            <Marker
              key={index}
              longitude={marker.longitude}
              latitude={marker.latitude}
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                handleMarkerClick(marker);
              }}
            >
              <PinComponent className='' />
            </Marker>
          )
        })}

        {residenceCoordinates && publicResidence && (
          <Marker
            longitude={residenceCoordinates.longitude}
            latitude={residenceCoordinates.latitude}
            onClick={(e) => {
              e.originalEvent.stopPropagation();
              handleResidenceMarkerClick();
            }}
          >
            <PinPro className="" />
          </Marker>
        )}

        {!isFullscreen && (
          <Fab
            className='bg-primary hover:bg-primary-hover'
            aria-label='add'
            onClick={handleFabClick}
            sx={{
              position: 'absolute',
              bottom: 20,
              right: 20,
              zIndex: 1000,
            }}
          >
            <AddIcon className='text-white' />
          </Fab>
        )}
      </Map>

      {isDefaultMarkerModalOpen && (
        <DefaultMarkerModal
          open={isDefaultMarkerModalOpen}
          onClose={() => setIsDefaultMarkerModalOpen(false)}
          place={residencePlace}
        />
      )}

      {newMarker && isModalOpen && isNewMarkerModal && (
        <MarkerModal
          marker={newMarker}
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setNewMarker(null);
            setIsNewMarkerModal(false);
          }}
          onSave={handleMarkerSubmit}
          onDelete={() => {
            setIsModalOpen(false);
            setNewMarker(null);
            setIsNewMarkerModal(false);
          }}
          isNewMarker={true}
          setMarker={setNewMarker}
        />
      )}

      {!newMarker && (
        <MarkerModal
          marker={null}
          open={false}
          onClose={() => { }}
          onSave={() => { }}
          onDelete={() => { }}
          isNewMarker={false}
          setMarker={() => { }}
        />
      )}

      {selectedMarker && isModalOpen && !isNewMarkerModal && (
        <InfoMarkerModal
          marker={selectedMarker}
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedMarker(null);
          }}
          onEdit={() => {
            setIsModalOpen(false);
            setIsEditMarkerModalOpen(true);
          }}
          onDelete={() => {
            const authToken = sessionStorage.getItem('authToken');
            const headers = {
              'Authorization': `Bearer ${authToken}`
            };
            axios.delete(`${url}/pins/${selectedMarker.id}/`, { headers })
              .then(() => {
                setIsModalOpen(false);
                setSelectedMarker(null);
                fetchMarkers();
                toast.success('Pin eliminato con successo', {
                  position: 'bottom-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                });
              })
              .catch((error) => {
                console.error('Error deleting marker:', error);
                toast.error('Errore nella cancellazione del pin', {
                  position: 'bottom-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                });
              });
          }}
        />
      )}

      {selectedMarker && isEditMarkerModalOpen && (
        <EditMarkerModal
          marker={selectedMarker}
          open={isEditMarkerModalOpen}
          onClose={() => {
            setIsEditMarkerModalOpen(false);
            setSelectedMarker(null);
            fetchMarkers();
          }}
          onSave={() => {
            setIsEditMarkerModalOpen(false);
            setSelectedMarker(null);
            fetchMarkers();
          }}
          onDelete={() => {
            const authToken = sessionStorage.getItem('authToken');
            const headers = {
              'Authorization': `Bearer ${authToken}`
            };
            axios.delete(`${url}/pins/${selectedMarker.id}/`, { headers })
              .then(() => {
                setIsModalOpen(false);
                setSelectedMarker(null);
                fetchMarkers();
                toast.success('Pin eliminato con successo', {
                  position: 'bottom-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                });
              })
              .catch((error) => {
                console.error('Error deleting marker:', error);
                toast.error('Errore nella cancellazione del pin', {
                  position: 'bottom-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                });
              });
          }}
        />
      )}

    </>
  );
}

export default MapBox