import { useContext, useState } from 'react'
import { TextField, Button, Checkbox, FormControlLabel, Container, Box, InputAdornment, IconButton } from '@mui/material'
import { LoginFormType } from '../../../types/login'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../enums/Approutes'
import { Grid } from '@mui/system';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { AuthContext } from '../../../hooks/authContext'
import { LoadingButton } from '@mui/lab'
import { toast } from 'react-toastify';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import GoogleButton from '../button/GoogleButton'

const url = process.env.REACT_APP_BASE_URL;

const LoginForm = () => {
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { errors }, reset } = useForm<LoginFormType>();
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const onSubmit: SubmitHandler<LoginFormType> = async (data) => {
        try {
            setLoading(true);
            const response = await axios.post(`${url}/users/login/`, {
                email: data.email,
                password: data.password
            });

            const { user, access, refresh } = response.data;
            const isVerified = user.is_verified;

            if (isVerified) {
                login(access, user.id);

                if (data.rememberMe) {
                    localStorage.setItem('refreshToken', refresh);
                }

                navigate(AppRoutes.PIN_MAP);
                setLoading(false);
            } else {
                try {
                    await axios.put(`${url}/users/send_activation_link/`, {
                        email: data.email
                    });
                    toast.info(t('toast:account_not_verified'), {
                        position: 'bottom-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored'
                    });
                    reset();
                    navigate(AppRoutes.LOGIN);
                    setLoading(false);
                } catch (error) {
                    console.error('Errore nell\'invio del link di attivazione:', error);
                    toast.error(t('toast:send_activation_link_error'), {
                        position: 'bottom-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored'
                    });
                }
            }

        } catch (error) {
            console.error(error);
            toast.error(t('toast:login_error'), {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
            setLoading(false);
        };
    };

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    boxShadow: 'none',
                    width: '100%',
                    padding: { xs: '0 1rem', sm: 0 }
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: t('login:emailRequired'),
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: t('login:invalidEmail'),
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Email"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                type='email'
                                error={!!errors.email}
                                helperText={errors.email ? errors.email.message : ''}
                                slotProps={{
                                    inputLabel: { shrink: true }
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#E0E0E0',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#E0E0E0',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#E0E0E0',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#000000',
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#000000',
                                    },
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        color: '#000000',
                                    },
                                }}
                            />
                        )}
                    />

                    <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: t('login:passwordRequired'),
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Password"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                type={showPassword ? 'text' : 'password'}
                                error={!!errors.password}
                                helperText={errors.password ? errors.password.message : ''}
                                slotProps={{
                                    inputLabel: { shrink: true },
                                    input: {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#E0E0E0',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#E0E0E0',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#E0E0E0',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#000000',
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#000000',
                                    },
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        color: '#000000',
                                    },
                                }}
                            />
                        )}
                    />

                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        className="mt-2"
                        spacing={2}
                        sx={{
                            flexDirection: { xs: 'column', sm: 'row' }
                        }}
                    >
                        <Grid size={{ xs: 12, sm: 6 }} sx={{ width: { xs: '100%', sm: 'auto' } }} className='contents'>
                            <Controller
                                name="rememberMe"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value}
                                                color="error"
                                            />
                                        }
                                        label={t('login:rememberMe')}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid size={{ xs: 12, sm: 6 }} sx={{ width: { xs: '100%', sm: 'auto' }, textAlign: 'right' }}>
                            <Button
                                type="button"
                                onClick={() => navigate(AppRoutes.RESET_PASSWORD)}
                                className="text-sm text-text-primary sm:text-xs font-bold"
                                fullWidth={true}
                                sx={{ width: { xs: '100%', sm: 'auto' } }}
                            >
                                {t('login:forgotPassword')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 0,
                            width: "100%",
                            margin: "1.2rem auto",
                        }}
                    >
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}
                            fullWidth
                            loading={loading}
                            className='bg-primary hover:bg-primary-hover'
                        >
                            {t('login:login')}
                        </LoadingButton>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                margin: '1rem 0',
                            }}
                        >
                            <Box
                                sx={{
                                    flex: 1,
                                    height: '1px',
                                    backgroundColor: '#E0E0E0',
                                }}
                            />
                            <Box
                                sx={{
                                    px: 2,
                                    fontSize: '0.875rem',
                                    fontWeight: 'bold',
                                    color: '#757575',
                                }}
                            >
                                {t('login:or')}
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    height: '1px',
                                    backgroundColor: '#E0E0E0',
                                }}
                            />
                        </Box>

                        <Box
                            sx={{
                                width: "100%",
                                height: 'auto',
                                textAlign: "center"
                            }}
                        >
                            <GoogleButton text={t('login:googleLogin')} />
                        </Box>

                    </Box>
                </form>
            </Box>
        </Container>
    )
}

export default LoginForm
