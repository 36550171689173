import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, FormControl, InputLabel, MenuItem, Select as MuiSelect, Checkbox, ListItemText, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import mbxGeocoding, { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding';
import axios from 'axios';
import Select, { SingleValue } from 'react-select';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { MarkerData } from '../../../types/markerData';
import { PlaceOption } from '../../../interfaces/Mapbox';
import { EditMarkerModalProps } from '../../../interfaces/EditMarker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const url = process.env.REACT_APP_BASE_URL;
const geocodingClient = mbxGeocoding({ accessToken: process.env.REACT_APP_MAPBOX_TOKEN! });

const EditMarkerModal: React.FC<EditMarkerModalProps> = ({ marker, open, onClose, onSave, onDelete }) => {
    const { t, i18n } = useTranslation();
    const [tripTypes, setTripTypes] = useState<string[]>([]);
    const [timesVisited, setTimesVisited] = useState<string[]>([]);
    const [placeOptions, setPlaceOptions] = useState<PlaceOption[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const userId = sessionStorage.getItem('userId');
    const authToken = sessionStorage.getItem('authToken');

    const { handleSubmit, control, reset, formState: { errors } } = useForm<MarkerData>({
        defaultValues: marker
    });

    useEffect(() => {
        const fetchTripTypes = async () => {
            const headers = {
                'Authorization': `Bearer ${authToken}`
            };
            try {
                const response = await axios.get(`${url}/users/trip_types/`, { headers });
                setTripTypes(response.data);
            } catch (error) {
                console.error('Error fetching travel types', error);
            }
        };

        const fetchTimesVisited = async () => {
            const headers = {
                'Authorization': `Bearer ${authToken}`
            };
            try {
                const response = await axios.get(`${url}/pins/times_visited/`, { headers });
                setTimesVisited(response.data);
            } catch (error) {
                console.error('Error fetching times visited', error);
            }
        };

        fetchTripTypes();
        fetchTimesVisited();
    }, [authToken]);

    useEffect(() => {
        reset(marker);
    }, [marker, reset]);

    const fetchPlaceSuggestions = async (inputValue: string) => {
        try {
            const response = await geocodingClient
                .forwardGeocode({
                    query: inputValue,
                    autocomplete: true,
                    limit: 5,
                    language: [i18n.language]
                })
                .send();

            const options = response.body.features.map((feature: GeocodeFeature) => ({
                label: feature.place_name,
                value: feature.place_name,
                center: feature.center as [number, number]
            }));
            setPlaceOptions(options);
        } catch (error) {
            console.error('Error fetching place suggestions:', error);
        }
    };

    if (!marker) return null;

    const onSubmit = async (data: MarkerData) => {
        try {
            setLoading(true);
            const authToken = sessionStorage.getItem('authToken');
            const headers = {
                'Authorization': `Bearer ${authToken}`
            };
            const markerData = {
                ...data,
                user: parseInt(userId!),
                longitude: marker.longitude,
                latitude: marker.latitude,
                date_last_visit: dayjs(data.date_last_visit, 'MM-YYYY').format('YYYY-MM')
            };
            console.log(markerData);
            await axios.put(`${url}/pins/${marker.id}/`, markerData, { headers });
            onSave();
            onClose();
            reset();
            setLoading(false);
            toast.success(t('toast:update_pin_success'), {
                position: 'bottom-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        } catch (error) {
            toast.error(t('toast:update_pin_error'), {
                position: 'bottom-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
            setLoading(false);
        }
    };

    const handleDelete = () => {
        onDelete();
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    {t('pinMap:editPin')} <span style={{ fontWeight: 'bold', color: '#E9495A' }}>Pin</span>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {/* Place Field */}
                        <FormControl fullWidth margin="normal" error={!!errors.place}>
                            <Controller
                                name="place"
                                control={control}
                                rules={{
                                    required: t('pinMap:placeRequired'),
                                }}
                                render={({ field }) => (
                                    <>
                                        <Select<PlaceOption>
                                            placeholder={t('pinMap:place')}
                                            inputId="place-select"
                                            isClearable
                                            options={placeOptions}
                                            required
                                            value={placeOptions.find(option => option.value === field.value) || { label: field.value, value: field.value }}
                                            onInputChange={(inputValue) => {
                                                if (inputValue) {
                                                    fetchPlaceSuggestions(inputValue);
                                                }
                                            }}
                                            onChange={(selectedOption: SingleValue<PlaceOption>) => {
                                                field.onChange(selectedOption?.value ?? '');
                                                if (selectedOption?.center) {
                                                    marker.latitude = selectedOption.center[1];
                                                    marker.longitude = selectedOption.center[0];
                                                }
                                            }}
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isSelected
                                                        ? '#E0E0E0'
                                                        : state.isFocused
                                                            ? '#E0E0E0'
                                                            : provided.backgroundColor,
                                                    color: state.isSelected
                                                        ? 'black'
                                                        : state.isFocused
                                                            ? 'black'
                                                            : provided.color,
                                                }),
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    borderColor: state.isFocused ? '#E0E0E0' : provided.borderColor,
                                                    boxShadow: state.isFocused ? '0 0 0 1px #E0E0E0' : provided.boxShadow,
                                                    '&:hover': {
                                                        borderColor: state.isFocused ? '#E0E0E0' : provided.borderColor,
                                                    },
                                                    height: '3.5rem'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    color: 'black',
                                                }),
                                            }}
                                            menuPortalTarget={document.body}
                                        />
                                        {errors.place && <span style={{ color: '#E9495A', fontSize: '0.75rem', paddingLeft: '0.7rem', paddingTop: '0.1rem' }}>{errors.place.message}</span>}
                                    </>
                                )}
                            />
                        </FormControl>

                        {/* Travel Types Field */}
                        <FormControl fullWidth margin="normal" error={!!errors.pin_type}>
                            <InputLabel
                                sx={{
                                    color: '#000000',
                                    '&.Mui-focused': {
                                        color: '#000000',
                                    },
                                }}
                            >
                                {t('pinMap:travelType')}
                            </InputLabel>
                            <Controller
                                name="pin_type"
                                control={control}
                                rules={{
                                    required: t('pinMap:travelTypeRequired'),
                                    validate: (value: string[]) =>
                                        value && value.length >= 1 && value.length <= 3
                                            ? true
                                            : t('pinMap:travelTypeValidation')
                                }}
                                render={({ field }) => {
                                    const selectedValues = field.value || [];
                                    const maxSelectionsReached = selectedValues.length >= 3;
                                    return (
                                        <MuiSelect
                                            {...field}
                                            label={t('pinMap:travelType')}
                                            required
                                            multiple
                                            renderValue={(selected) => selected.join(', ')}
                                            onChange={(event) => {
                                                const value = event.target.value as string[];
                                                if (value.length <= 3) {
                                                    field.onChange(value);
                                                }
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#E0E0E0',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#E0E0E0',
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#E0E0E0',
                                                },
                                                '& .MuiSelect-select': {
                                                    color: '#000000',
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: '#000000',
                                                },
                                            }}
                                        >
                                            {tripTypes.map((type) => {
                                                const isSelected = selectedValues.includes(type);
                                                const isDisabled = !isSelected && maxSelectionsReached;
                                                return (
                                                    <MenuItem key={type} value={type} disabled={isDisabled}>
                                                        <Checkbox checked={isSelected} color='error' />
                                                        <ListItemText primary={type} />
                                                    </MenuItem>
                                                );
                                            })}
                                        </MuiSelect>
                                    );
                                }}
                            />
                            {errors.pin_type && (
                                <span style={{ color: '#E9495A', fontSize: '0.75rem', paddingLeft: '0.7rem', paddingTop: '0.1rem' }}>{errors.pin_type.message}</span>
                            )}
                        </FormControl>

                        {/* Times Visited Field */}
                        <FormControl fullWidth margin="normal" error={!!errors.times_visited}>
                            <InputLabel
                                sx={{
                                    color: '#000000',
                                    '&.Mui-focused': {
                                        color: '#000000',
                                    },
                                }}
                            >
                                {t('pinMap:timeVisited')}
                            </InputLabel>
                            <Controller
                                name="times_visited"
                                control={control}
                                rules={{
                                    required: t('pinMap:timeVisitedRequired')
                                }}
                                render={({ field }) => (
                                    <MuiSelect
                                        {...field}
                                        value={field.value}
                                        label={t('pinMap:timeVisited')}
                                        required
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#E0E0E0',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#E0E0E0',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#E0E0E0',
                                            },
                                            '& .MuiSelect-select': {
                                                color: '#000000',
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: '#000000',
                                            },
                                        }}
                                    >
                                        {timesVisited.map((time: string, index: number) => (
                                            <MenuItem key={index} value={index}>
                                                {time}
                                            </MenuItem>
                                        ))}
                                    </MuiSelect>
                                )}
                            />
                        </FormControl>

                        {/* Knowledge Level Field */}
                        <FormControl fullWidth margin="normal">
                            <InputLabel
                                sx={{
                                    color: '#000000',
                                    '&.Mui-focused': {
                                        color: '#000000',
                                    },
                                }}
                            >
                                {t('pinMap:knowledge')}
                            </InputLabel>
                            <Controller
                                name="knowledge_level"
                                control={control}
                                render={({ field }) => (
                                    <MuiSelect
                                        {...field}
                                        value={field.value}
                                        label={t('pinMap:knowledge')}
                                        required
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#E0E0E0',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#E0E0E0',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#E0E0E0',
                                            },
                                            '& .MuiSelect-select': {
                                                color: '#000000',
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: '#000000',
                                            },
                                        }}
                                    >
                                        <MenuItem value="Poco">Poco</MenuItem>
                                        <MenuItem value="Abbastanza">Abbastanza</MenuItem>
                                        <MenuItem value="Molto">Molto</MenuItem>
                                        <MenuItem value="Pinner Pro">Pinner Pro</MenuItem>
                                    </MuiSelect>
                                )}
                            />
                        </FormControl>

                        {/* Date Last Visit Field */}
                        <FormControl fullWidth margin="normal" error={!!errors.date_last_visit}>
                            <Controller
                                name="date_last_visit"
                                control={control}
                                rules={{
                                    required: t('pinMap:visitDateRequired'),
                                }}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label={t('pinMap:visitDate')}
                                        views={['year', 'month']}
                                        format='MM/YYYY'
                                        value={field.value ? dayjs(field.value, 'MM-YYYY') : null}
                                        onChange={(newValue) => {
                                            field.onChange(newValue ? newValue.format('MM-YYYY') : '');
                                        }}
                                        slotProps={{
                                            textField: {
                                                error: !!errors.date_last_visit,
                                                helperText: errors.date_last_visit ? errors.date_last_visit.message : '',
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '1rem',
                                                    marginBottom: '0.5rem',
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: '#E0E0E0',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#E0E0E0',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#E0E0E0',
                                                        },
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: '#000000',
                                                    },
                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                        color: '#000000',
                                                    },
                                                    '& .MuiInputBase-input.Mui-disabled': {
                                                        color: '#000000',
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        </FormControl>

                        {/* Photo Link Field */}
                        <Controller
                            name="photo_link"
                            control={control}
                            rules={{ maxLength: 300 }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="url"
                                    label={
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            {t('pinMap:photoLink')}
                                            <LockOutlinedIcon style={{ marginLeft: 6 }} />
                                        </span>
                                    }
                                    value={field.value}
                                    onChange={(e) => {
                                        const value = e.target.value.slice(0, 300);
                                        field.onChange(value);
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    helperText={`${field.value?.length ?? 0}/300`}
                                    slotProps={{ inputLabel: { shrink: true } }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#E0E0E0',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#E0E0E0',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#E0E0E0',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#000000',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#000000',
                                        },
                                        '& .MuiInputBase-input.Mui-disabled': {
                                            color: '#000000',
                                        },
                                    }}
                                />
                            )}
                        />

                        {/* Content Field */}
                        <Controller
                            name="content"
                            control={control}
                            rules={{ maxLength: 1000 }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            {t('pinMap:notes')}
                                            <LockOutlinedIcon style={{ marginLeft: 6 }} />
                                        </span>
                                    }
                                    value={field.value}
                                    onChange={(e) => {
                                        const value = e.target.value.slice(0, 1000);
                                        field.onChange(value);
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    margin="normal"
                                    helperText={`${field.value?.length ?? 0}/1000`}
                                    slotProps={{
                                        inputLabel: { shrink: true }
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#E0E0E0',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#E0E0E0',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#E0E0E0',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#000000',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#000000',
                                        },
                                        '& .MuiInputBase-input.Mui-disabled': {
                                            color: '#000000',
                                        },
                                    }}
                                />
                            )}
                        />
                    </Box>
                </DialogContent>
                <DialogActions className='flex justify-center items-center' sx={{ paddingRight: { xs: 'none', sm: '1.5rem' } }}>
                    <Button
                        onClick={handleDelete}
                        sx={{ margin: '1rem', fontSize: { xs: '0.875rem', sm: '1rem' }, backgroundColor: 'trasparent', color: '#475467', border: '1px solid #667085' }}
                        fullWidth
                    >
                        {t('common:delete')}
                    </Button>
                    <LoadingButton
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        sx={{ margin: '1rem', fontSize: { xs: '0.875rem', sm: '1rem' }, backgroundColor: '#E9495A', color: '#FFFFFF', '&:hover': { backgroundColor: '#E6263B' } }}
                        fullWidth
                    >
                        {t('common:save')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    );
};

export default EditMarkerModal;
