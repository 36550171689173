import { useState } from 'react'
import { Box, Typography, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import { KeyIcon, MailIcon } from '../../assets/icons/Index';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { ResetPasswordType } from '../../types/resetPassword';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const url = process.env.REACT_APP_BASE_URL;

const ResetPassword = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1)
  const [email, setEmail] = useState('')
  const { control, handleSubmit } = useForm<ResetPasswordType>()
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate()

  const onSubmit: SubmitHandler<ResetPasswordType> = async data => {
    try {
      setLoading(true);
      setEmail(data.email);
      const response = await axios.put(`${url}/users/send_reset_password_email/`, {
        "email": data.email
      });
      if (response) {
        setStep(2);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 404) {
          toast.error(t('toast:reset_password_not_found'), {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
          });
        } else if (error.response.status === 400) {
          toast.error(t('toast:reset_password_error'), {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
          });
        }
      } else {
        console.error('Unexpected error:', error);
        toast.error(t('toast:reset_password_error'), {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        });
      }
    }
  };

  const handleResendEmail = () => {
    onSubmit({ email })
    toast.success(t('toast:reset_password_success'), {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
    });
    setLoading(false);
  }

  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <Box component={'div'} className='flex flex-col justify-center items-center px-4 sm:px-0 max-w-md w-full gap-4'>
        {step === 1 && (
          <>
            <KeyIcon className='w-14 h-14' />
            <Typography variant='h4' className='text-text-primary text-2xl font-bold text-center'>
              {t('resetPassword:title')}
            </Typography>
            <Typography variant='body1' className='text-text-secondary text-base text-center'>
              {t('resetPassword:subtitle')}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} className='w-full flex flex-col gap-4 mt-6'>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: t('resetPassword:mail_required'), pattern: { value: /^\S+@\S+$/i, message: t('resetPassword:mail_invalid') } }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Email"
                    variant="outlined"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    slotProps={{
                      inputLabel: { shrink: true }
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#E0E0E0',
                        },
                        '&:hover fieldset': {
                          borderColor: '#E0E0E0',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#E0E0E0',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#000000',
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#000000',
                      },
                      '& .MuiInputBase-input.Mui-disabled': {
                        color: '#000000',
                      },
                    }}
                  />
                )}
              />
              <LoadingButton
                type='submit'
                variant='contained'
                fullWidth
                loading={loading}
                className='bg-primary hover:bg-primary-hover'
              >
                {t('resetPassword:button')}
              </LoadingButton>
            </form>
          </>
        )}
        {step === 2 && (
          <>
            <MailIcon className='w-14 h-14' />
            <Typography variant='h4' className='text-text-primary text-2xl font-bold text-center'>
              {t('resetPassword:checkEmail')}
            </Typography>
            <Typography variant='body1' className='text-text-secondary text-base text-center'>
              {t('resetPassword:checkEmailSubtitle')}
              <span className='font-bold'>{email}</span>
            </Typography>
            <Typography variant='body1' className='text-text-secondary text-base text-center'>
              {t('resetPassword:checkEmailResend')}
              <button
                className='text-primary font-bold cursor-pointer ml-1'
                onClick={handleResendEmail}
              >
                {t('resetPassword:checkEmailResendButton')}
              </button>
            </Typography>
          </>
        )}
        <Typography
          variant='body2'
          className='cursor-pointer flex items-center text-text-secondary font-bold mt-4'
          onClick={() => navigate('/login')}
        >
          <ArrowBackIcon fontSize='small' className='mr-1' />
          {t('resetPassword:back')}
        </Typography>
      </Box>
    </div>
  )
}

export default ResetPassword