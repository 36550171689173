import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { AppRoutes } from './enums/Approutes';
import Login from './pages/login/Login';
import ResetPassword from './pages/resetPassword/ResetPassword';
import SetNewPassword from './pages/resetPassword/SetNewPassword';
import PinMap from './pages/pinMap/PinMap';
import Settings from './pages/settings/Settings';
import PrivateRoute from './routes/PrivateRoute';
import Pinners from './pages/pinners/Pinners';
import Consultancy from './pages/consultancy/Consultancy';
import NotFound from './pages/404/NotFound';
import WelcomePage from './pages/welcome/WelcomePage';
import ProfilePage from './pages/profile/ProfilePage';
import Navbar from './components/specific/navbar/Navbar';
import Footer from './components/specific/footer/Footer';
import OnBoarding from './pages/onBoarding/OnBoarding';
import Notifications from './pages/notifications/Notifications';
import { AuthProvider } from './hooks/authContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthLoader from './hooks/authLoader';
import Feedback from './pages/feedback/Feedback';
import { GoogleOAuthProvider } from '@react-oauth/google';

const googleClient = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

const App = () => {

  return (
    <GoogleOAuthProvider clientId={googleClient}>
      <AuthProvider>
        <Router>
          <ToastContainer />
          <AuthLoader>
            {/* Public Routes */}
            <Routes>
              <Route path={AppRoutes.LOGIN} element={<Login />} />
              <Route path={AppRoutes.RESET_PASSWORD} element={<ResetPassword />} />
              <Route path={AppRoutes.SET_NEW_PASSWORD} element={<SetNewPassword />} />
              <Route path={AppRoutes.WELCOME} element={<WelcomePage />} />
              <Route path={AppRoutes.ON_BOARDING} element={<OnBoarding />} />
              <Route path={AppRoutes.PROFILE} element={<ProfilePage />} />
              <Route path="*" element={<NotFound />} />

              {/* Private Routes */}
              <Route element={<PrivateRoute />}>
                <Route
                  element={
                    <>
                      <Navbar />
                      <Outlet />
                      <Footer />
                    </>
                  }
                >
                  <Route path="/" element={<PinMap />} />
                  <Route path={AppRoutes.FEEDBACK} element={<Feedback />} />
                  <Route path={AppRoutes.SETTINGS} element={<Settings />} />
                  <Route path={AppRoutes.NOTIFICATIONS} element={<Notifications />} />
                  <Route path={AppRoutes.PINNERS} element={<Pinners />} />
                  <Route path={AppRoutes.CONSULTANCY} element={<Consultancy />} />
                </Route>
              </Route>
            </Routes>
          </AuthLoader>
        </Router>
      </AuthProvider>
    </GoogleOAuthProvider>
  )
}

export default App