import React, { useState } from 'react'
import { Logo } from '../../assets/icons/Index'
import { Box, Typography, Tabs, Tab } from '@mui/material'
import LoginForm from '../../components/specific/form/LoginForm'
import RegisterForm from '../../components/specific/form/RegisterForm'
import { AuthTab } from '../../enums/AuthTab'
import { useTranslation } from 'react-i18next'


const Login = () => {
  const [activeTab, setActiveTab] = useState<AuthTab>(() => {
    const isLoggedIn = localStorage.getItem('authToken');
    return isLoggedIn ? AuthTab.REGISTER : AuthTab.LOGIN;
  });
  const { t } = useTranslation();

  const handleTabChange = (event: React.SyntheticEvent, newValue: AuthTab) => {
    setActiveTab(newValue);
  };

  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <Box component={'div'} className='flex flex-col justify-center items-center pt-8 pb-2 px-4 sm:px-0 max-w-md w-full'>
        <Logo className='w-14 h-14 mb-2' />
        <Typography variant='h4' className='text-text-primary text-2xl font-medium'>
          {activeTab === AuthTab.LOGIN ? t('login:login') : t('login:register')}
        </Typography>
        <Typography variant='body1' className='text-text-secondary text-base'>
          {activeTab === AuthTab.LOGIN ? t('login:loginDescription') : t('login:registerDescription')}
        </Typography>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="login register tabs"
          className='my-4'
          TabIndicatorProps={{
            style: {
              backgroundColor: '#E9495A',
            },
          }}
        >
          <Tab label={t('login:register')} value={AuthTab.REGISTER} sx={{
            '&.Mui-selected': {
              color: '#E9495A',
            },
          }} />
          <Tab label={t('login:login')} value={AuthTab.LOGIN} sx={{
            '&.Mui-selected': {
              color: '#E9495A',
            },
          }} />
        </Tabs>
        {activeTab === AuthTab.LOGIN ? <LoginForm /> : <RegisterForm />}
        <Typography variant='body1' className='text-text-secondary text-base'>
          {activeTab === AuthTab.LOGIN ? (
            <>{t('login:noAccount')} <button onClick={() => setActiveTab(AuthTab.REGISTER)} className="text-button bg-transparent border-none p-0 cursor-pointer text-primary font-bold">{t('login:register')}</button></>
          ) : (
            <> {t('login:alreadyAccount')} <button onClick={() => setActiveTab(AuthTab.LOGIN)} className="text-button bg-transparent border-none p-0 cursor-pointer text-primary font-bold">{t('login:login')}</button></>
          )}
        </Typography>
      </Box>
    </div>
  )
}

export default Login
