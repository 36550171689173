import { CardProps } from "../../../interfaces/Card";
import { useTranslation } from 'react-i18next';
import './card.css';

const Card: React.FC<CardProps> = ({
  className = '',
  imgClassName = '',
  iconClassName = '',
  imgSrc,
  icon,
  title,
  description,
  lastVisit,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`w-64 h-72 p-5 flex flex-col justify-between shadow-lg rounded-md m-auto ${className}`}>
      <div className="w-full h-auto">
        {imgSrc &&
          <img
            src={imgSrc}
            alt={title}
            className={`${imgClassName} rounded-sm`}
          />}
      </div>
      {icon && <div className={iconClassName}>{icon}</div>}
      <div className="w-full h-auto text-left">
        <h2 className="text-primary font-bold">{title}</h2>
      </div>
      <div className="w-full h-auto text-left">
        <p className="text-text-tertiary text-sm my-2 description-truncate">{description}</p>
      </div>
      <div className="w-full">
        {lastVisit && <p className="text-text-tertiary text-sm text-left">{t('common:lastVisit')} <strong>{lastVisit}</strong></p>}
      </div>
      {children}

    </div>
  );
};

export default Card;