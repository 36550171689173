import { StepTrackerProps } from "../../interfaces/StepTracker"
import { Typography, Icon } from "@mui/material"

const StepTracker: React.FC<StepTrackerProps> = ({ steps, currentStep }) => {

    return (
        <div className="w-full flex flex-col gap-12 items-center justify-center py-10 relative">
            {steps.map((step, index) => {
                const isActive = index <= currentStep;
                return (
                    <div key={index} className='w-full flex items-start gap-4'>
                        <div className="relative">
                            <Icon className={`mt-1 h-auto z-10 relative text-text-primary' ${isActive ? 'text-primary' : 'text-text-tertiary'}`}>
                                {step.icon}
                            </Icon>
                            {index < steps.length - 1 && (
                                <div className='absolute top-14 left-1/2 transform -translate-x-1/2 w-0.5 h-8 bg-text-tertiary'></div>
                            )}
                        </div>
                        <div className="flex flex-col">
                            <Typography variant="body2" className='text-sm font-semibold text-text-primary'>
                                {step.title}
                            </Typography>
                            <Typography variant="body2" className="text-xs text-text-primary">
                                {step.description}
                            </Typography>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default StepTracker