import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../enums/Approutes'
import NoTFound from '../../assets/gifs/404.gif'

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate(AppRoutes.LOGIN)
  }

  return (
    <div className='w-full h-screen flex justify-center items-center p-4'>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 6 }}>
        <Typography variant='h4' sx={{ color: '#101828', fontWeight: 'medium' }}>
          {t('notFound:title')}
        </Typography>

        <img src={NoTFound} alt='' className='w-100 h-64 object-cover' />

        <Button onClick={goToLogin} sx={{ margin: '1rem', fontSize: { xs: '0.875rem', sm: '1rem' }, backgroundColor: 'trasparent', color: '#475467', border: '1px solid #667085' }} fullWidth>
          {t('notFound:login')}
        </Button>
      </Box>
    </div>
  )
}

export default NotFound
