import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import MapBox from '../../components/specific/map/MapBox';
import Card from '../../components/common/cards/Card';
import ModalShareMap from '../../components/specific/modal/ModalSharemap';
import { useEffect, useState } from 'react';
import { useMarkers } from '../../hooks/useMarker';
import EmptyState from '../../assets/gifs/empty_state_cards.gif';

const PinMap = () => {
  const { markers } = useMarkers();
  const userId = sessionStorage.getItem('userId');
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (markers.length === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [markers]);

  const disabledStyle = {
    backgroundColor: '#DBDBDB',
    color: '#475467'
  }

  const buttonStyle = {
    cursor: 'pointer',
    backgroundColor: '#E9495A',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#E6263B'
    }
  }

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleShareMap = () => {
    const mapLink = `${window.location.origin}/profile/${userId}`;
    const staticImageUrl = getSharedMapImageUrl(markers.map(marker => ({
      longitude: marker.longitude,
      latitude: marker.latitude,
    })));

    return { mapLink, staticImageUrl };
  };

  const getStaticMapImageUrl = (longitude: number, latitude: number) => {
    const accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    const width = 200;
    const height = 90;
    const zoom = 6;
    return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${longitude},${latitude},${zoom},0,0/${width}x${height}?access_token=${accessToken}`;
  };

  const getSharedMapImageUrl = (markers: { longitude: number; latitude: number }[]) => {
    const accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    const width = 1200;
    const height = 630;
    const zoom = 5;
    const center = markers.length > 0
      ? `${markers[0].longitude},${markers[0].latitude}`
      : "0,0";

    const markerOverlay = markers
      .map(marker => `pin-s+FF0000(${marker.longitude},${marker.latitude})`)
      .join(',');

    return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${markerOverlay}/${center},${zoom}/${width}x${height}?access_token=${accessToken}`;
  };


  return (
    <>
      <div className='w-full h-auto flex flex-col justify-center items-center p-6'>
        <div className='flex flex-col items-center mt-4 mb-2 px-4 sm:mt-6 mb-4 text-center'>
          <p className='text-primary font-bold text-sm sm:text-base'>
            Pin Your Places
          </p>
          <Typography variant='h3' className='text-text-primary font-medium text-xl sm:text-2xl md:text-2xl mt-2'>
            {t('pinMap:titleLeft')} <span className='text-primary font-bold'>Pin</span>{t('pinMap:titleRight')}
          </Typography>
        </div>
        <MapBox />
        <div className='w-full h-auto flex justify-center items-center my-14'>
          <Button variant="contained" className='w-72 h-14' style={isDisabled ? disabledStyle : buttonStyle} onClick={openModal} disabled={isDisabled}>
            {t('pinMap:share_button')}
          </Button>
        </div>
        <div className='flex flex-col items-center mt-4 px-4 sm:mt-6 mb-4 text-center'>
          <Typography variant='h4' className='text-text-primary font-medium text-xl sm:text-xl md:text-2xl mt-2'>
            {t('pinMap:subtitle')}
          </Typography>
          {markers.length > 0 ? (
            <div className='grid grid-cols-1 gap-8 w-auto sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 lg:gap-20 w-full justify-center items-center my-8'>
              {markers.slice(-6).reverse().map((marker) => (
                <Card
                  key={marker.id}
                  title={marker.place.split(',')[0].trim()}
                  description={marker.content}
                  imgSrc={getStaticMapImageUrl(marker.longitude, marker.latitude)}
                  lastVisit={String(marker.date_last_visit)}
                  imgClassName='w-full h-full object-cover'
                />
              ))}
            </div>
          ) : (
            <div className='flex flex-col items-center mt-4 px-4 sm:mt-6 mb-4 text-center'>
              <p className="text-sm text-muted-foreground mb-4">{t('pinMap:no_data')}</p>
              <img src={EmptyState} alt='Empty state' style={{ width: '9rem', height: 'auto', objectFit: 'cover' }} />
            </div>
          )}
        </div>
      </div>

      <ModalShareMap
        isOpen={isModalOpen}
        onClose={closeModal}
        link={handleShareMap().mapLink}
        image={handleShareMap().staticImageUrl}
      />
    </>
  )
}

export default PinMap