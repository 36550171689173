import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../hooks/authContext";
import { AppRoutes } from "../enums/Approutes";
import { MarkerProvider } from "../hooks/useMarker";

const PrivateRoute = () => {
    const { isAuthenticated } = useContext(AuthContext);

    return isAuthenticated ? (
        <MarkerProvider>
            <Outlet />
        </MarkerProvider>
    ) : (
        <Navigate to={AppRoutes.LOGIN} />
    );
}

export default PrivateRoute;