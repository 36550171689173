import React, { createContext, useState, useEffect, useMemo } from 'react';
import { AuthContextProp } from '../interfaces/Auth';
import axios from 'axios';

const url = process.env.REACT_APP_BASE_URL;

export const AuthContext = createContext<AuthContextProp>({
  isAuthenticated: null,
  authToken: null,
  userId: null,
  login: () => { },
  logout: () => { },
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);

  const getAuthTokenFromRefresh = async (refreshToken: string) => {
    try {
      const response = await axios.post(`${url}/users/token_refresh/`, {
        refresh: refreshToken
      });
      return response.data;
    } catch (error) {
      console.error('Error find refreshToken', error);
    }
  };

  useEffect(() => {
    const refreshToken = localStorage.getItem('refreshToken');
    const authToken = sessionStorage.getItem('authToken');
    if (refreshToken) {
      getAuthTokenFromRefresh(refreshToken)
        .then(
          (data) => {
            login(data.access, data.user.id);
          }
        )
    } else if (authToken) {
      setAuthToken(authToken);
      setIsAuthenticated(true);
    } else {
      setAuthToken(null);
      setIsAuthenticated(false);
    }
  }, []);

  const login = (token: string, userId: string) => {
    sessionStorage.setItem('authToken', token);
    sessionStorage.setItem('userId', userId);
    setAuthToken(token);
    setUserId(userId);
    setIsAuthenticated(true);
  };

  const logout = () => {
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('userId');
    setAuthToken(null);
    setUserId(null);
    setIsAuthenticated(false);
  };

  const value = useMemo(
    () => ({ isAuthenticated, authToken, userId, login, logout }),
    [isAuthenticated, authToken, userId]
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
