import { useTranslation } from 'react-i18next';
import { FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#E9495A',
    '&:hover': {
      backgroundColor: theme.palette.action.hoverOpacity,
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#E9495A',
  },
}));

const LanguageSwitcher = ({ className }: { className: string }) => {
  const { i18n } = useTranslation();

  const handleLanguageChange = () => {
    const newLang = i18n.language === 'it' ? 'en' : 'it';
    i18n.changeLanguage(newLang);
  };

  return (
    <div className={className}>
      <FormControlLabel
        control={<CustomSwitch color='error' checked={i18n.language === 'it'} onChange={handleLanguageChange} />}
        label={i18n.language === 'it' ? '🇮🇹' : '🇬🇧'}
        className="text-sm md:text-base"
      />
    </div>
  );
};

export default LanguageSwitcher;
