import { Box, Typography, Icon, TextField, Button, InputAdornment, Chip } from '@mui/material'
import { LoadingButton } from '@mui/lab';
import StepTracker from '../../components/specific/StepTracker'
import { useEffect, useState } from 'react';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseIcon from '@mui/icons-material/House';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import { Logo } from '../../assets/icons/Index';
import LogoPinnalo from '../../assets/images/logo-black.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { WelcomeFormType } from '../../types/welcome';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const url = process.env.REACT_APP_BASE_URL;
const iconsMap: { [key: string]: string } = {
  "Avventuriero": "🎒",
  "Sportivo": "🏃‍♂️",
  "Famiglia": "🏡",
  "Famiglia con Bambini": "👨‍👩‍👧‍👦",
  "Relax": "🏖️",
  "Food": "🍽️",
  "Nomade": "🌍",
  "Business": "💼",
  "Culturale": "🎨",
  "Influencer": "📸",
  "Divertimento": "🎉"
};

const WelcomePage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dataFromGoogle = location.state as { first_name?: string; last_name?: string, email: string };
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { handleSubmit, formState: { errors }, trigger, control } = useForm<WelcomeFormType>({
    defaultValues: {
      first_name: dataFromGoogle?.first_name || '',
      last_name: dataFromGoogle?.last_name || '',
      email: dataFromGoogle.email
    }
  })
  const [tripTypes, setTripTypes] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    const fetchTripTypes = async () => {
      const authToken = sessionStorage.getItem('authToken');
      const headers = {
        'Authorization': `Bearer ${authToken}`,
      };
      try {
        const response = await axios.get(`${url}/users/trip_types/`, { headers });
        setTripTypes(response.data);
      } catch (error) {
        console.error('Errore nel recupero dei dati', error);
      }
    };

    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    fetchTripTypes();
    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const onSubmit: SubmitHandler<WelcomeFormType> = async data => {
    try {
      setIsLoading(true);
      const authToken = sessionStorage.getItem('authToken');
      const headers = {
        'Authorization': `Bearer ${authToken}`,
      };
      //const getEmail = (await axios.get(`${url}/users/auth/profile/`, { headers })).data.email;
      console.log(data);
      const response = await axios.patch(`${url}/users/auth/profile/`, data, { headers });
      console.log(response);
      const sendEmail = await axios.put(`${url}/users/send_activation_link/`, {
        email: data.email
      }, { headers });
      if (response && sendEmail) {
        setIsLoading(false);
        setIsSubmitted(true);
      }
    } catch (error) {
      console.error('Errore durante l\'aggiornamento', error);
      toast.error(t('toast:welcome_error'), {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      });
    }
    setIsLoading(false);
  };

  const steps = [
    {
      icon: <PersonRoundedIcon />,
      title: t('welcome:first_step_title'),
      description: t('welcome:first_step_subtitle')
    },
    {
      icon: <HouseIcon />,
      title: t('welcome:second_step_title'),
      description: t('welcome:second_step_subtitle')
    },
    {
      icon: <MapRoundedIcon />,
      title: t('welcome:third_step_title'),
      description: t('welcome:third_step_subtitle')
    }
  ];

  const nextStep = async () => {
    let isValid = false;
    switch (currentStep) {
      case 0:
        isValid = await trigger(['first_name', 'last_name', 'birth_date']);
        break;
      case 1:
        isValid = await trigger(['residence', 'phone_number']);
        break;
      default:
        isValid = true;
    }
    if (isValid && currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className={`w-full h-full overflow-hidden flex ${isSubmitted ? 'flex-col justify-center items-center' : ''}`}>
      {!isMobile && (
        <aside className={`w-92 h-screen fixed top-0 left-0 flex flex-col justify-center items-center bg-box p-6 ${isSubmitted ? 'hidden' : 'flex'}`}>
          <img src={LogoPinnalo} alt='Logo' className='w-18 h-14 onject-cover' />
          <StepTracker steps={steps} currentStep={currentStep} />
        </aside>
      )}
      {!isSubmitted ? (
        <Box component={'div'} className={`w-full h-screen display-flex flex-col justify-center items-center overflow-hidden ${!isMobile ? 'ml-40' : 'ml-0'}`}>
          <div className='w-full h-full flex flex-col justify-center items-center px-4 ml-18 sm:px-0'>
            {steps.map((step, index) => (
              <div key={index} className={`flex flex-col items-center w-full sm:w-auto ${index === currentStep ? '' : 'hidden'}`}>
                <Icon className='h-auto text-4xl sm:text-5xl'>{step.icon}</Icon>
                <Typography variant='h4' className='text-text-primary text-xl sm:text-2xl font-bold text-center mt-4'>
                  {step.title}
                </Typography>
                <Typography variant='body1' className='text-text-secondary text-sm sm:text-base text-center mt-2 mb-4'>
                  {step.description}
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)} className="w-full sm:w-96">
                  {currentStep === 0 && (
                    <>
                      <Controller
                        name="first_name"
                        control={control}
                        rules={{ required: t('welcome:first_name_required') }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t('welcome:first_name_label')}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={!!errors.first_name}
                            helperText={errors.first_name ? errors.first_name.message : ''}
                            slotProps={{
                              inputLabel: { shrink: true }
                            }}
                            sx={{
                              margin: '0.5rem 0',
                              fontSize: { xs: '0.875rem', sm: '1rem' },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                              },
                              '& .MuiInputLabel-root': {
                                color: '#000000',
                              },
                              '& .MuiInputLabel-root.Mui-focused': {
                                color: '#000000',
                              },
                              '& .MuiInputBase-input.Mui-disabled': {
                                color: '#000000',
                              },
                            }}
                          />
                        )}
                      />

                      <Controller
                        name="last_name"
                        control={control}
                        rules={{ required: t('welcome:last_name_required') }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t('welcome:last_name_label')}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={!!errors.last_name}
                            helperText={errors.last_name ? errors.last_name.message : ''}
                            slotProps={{
                              inputLabel: { shrink: true }
                            }}
                            sx={{
                              margin: '0.5rem 0',
                              fontSize: { xs: '0.875rem', sm: '1rem' },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                              },
                              '& .MuiInputLabel-root': {
                                color: '#000000',
                              },
                              '& .MuiInputLabel-root.Mui-focused': {
                                color: '#000000',
                              },
                              '& .MuiInputBase-input.Mui-disabled': {
                                color: '#000000',
                              },
                            }}
                          />
                        )}
                      />

                      <Controller
                        name="birth_date"
                        control={control}
                        rules={{ required: t('welcome:birth_date_required') }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t('welcome:birth_date_label')}
                            value={field.value}
                            type="date"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            required
                            slotProps={{ inputLabel: { shrink: true } }}
                            error={!!errors.birth_date}
                            helperText={errors.birth_date ? errors.birth_date.message : ''}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                              },
                              '& .MuiInputLabel-root': {
                                color: '#000000',
                              },
                              '& .MuiInputLabel-root.Mui-focused': {
                                color: '#000000',
                              },
                              '& .MuiInputBase-input.Mui-disabled': {
                                color: '#000000',
                              },
                            }}
                          />
                        )}
                      />

                      <Button
                        variant="contained"
                        sx={{ margin: '1rem auto', fontSize: { xs: '0.875rem', sm: '1rem' } }}
                        fullWidth
                        className='bg-primary hover:bg-primary-hover'
                        onClick={nextStep}
                      >
                        {t('common:next')}
                      </Button>
                    </>
                  )}
                  {currentStep === 1 && (
                    <>
                      <Controller
                        name="residence"
                        control={control}
                        rules={{ required: t('welcome:residence_required') }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t('welcome:residence_label')}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={!!errors.residence}
                            helperText={errors.residence ? errors.residence.message : ''}
                            slotProps={{
                              inputLabel: { shrink: true },
                              input: {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <HouseIcon />
                                  </InputAdornment>
                                ),
                              },
                            }}
                            sx={{
                              margin: '0.5rem 0',
                              fontSize: { xs: '0.875rem', sm: '1rem' },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                              },
                              '& .MuiInputLabel-root': {
                                color: '#000000',
                              },
                              '& .MuiInputLabel-root.Mui-focused': {
                                color: '#000000',
                              },
                              '& .MuiInputBase-input.Mui-disabled': {
                                color: '#000000',
                              },
                            }}
                          />
                        )}
                      />

                      <Controller
                        name="phone_number"
                        control={control}
                        rules={{
                          pattern: {
                            value: /(^[03][0-9]{5,10}$)/,
                            message: t('welcome:phone_number_invalid'),
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t('welcome:phone_number_label')}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            type='text'
                            error={!!errors.phone_number}
                            helperText={errors.phone_number ? errors.phone_number.message : ''}
                            slotProps={{
                              inputLabel: { shrink: true },
                              input: {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocalPhoneIcon />
                                  </InputAdornment>
                                )
                              },
                            }}
                            sx={{
                              margin: '0.5rem 0',
                              fontSize: { xs: '0.875rem', sm: '1rem' },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                              },
                              '& .MuiInputLabel-root': {
                                color: '#000000',
                              },
                              '& .MuiInputLabel-root.Mui-focused': {
                                color: '#000000',
                              },
                              '& .MuiInputBase-input.Mui-disabled': {
                                color: '#000000',
                              },
                            }}
                          />
                        )}
                      />

                      <Button
                        variant="contained"
                        sx={{ margin: '1rem auto', fontSize: { xs: '0.875rem', sm: '1rem' } }}
                        fullWidth
                        className='bg-primary hover:bg-primary-hover'
                        onClick={nextStep}
                      >
                        {t('common:next')}
                      </Button>
                    </>
                  )}
                  {currentStep === 2 && (
                    <>
                      <Controller
                        name="interested_to"
                        control={control}
                        rules={{ required: t('welcome:interested_to_required') }}
                        defaultValue={[]}
                        render={({ field, fieldState }) => {
                          const { value, onChange } = field;
                          return (
                            <>
                              <Box className="flex flex-wrap gap-4 my-4 sm:my-8">
                                {tripTypes.map((type) => (
                                  <Chip
                                    key={type}
                                    label={
                                      <span>
                                        {iconsMap[type]}
                                        {' '}{type.charAt(0).toUpperCase() + type.slice(1)}
                                      </span>
                                    }
                                    onClick={() => {
                                      const currentIndex = value?.indexOf(type);
                                      let newSelected = value ? [...value] : [];

                                      if (currentIndex === -1 || currentIndex === undefined) {
                                        newSelected.push(type);
                                      } else {
                                        newSelected.splice(currentIndex, 1);
                                      }
                                      onChange(newSelected);
                                    }}
                                    variant={value?.includes(type) ? 'filled' : 'outlined'}
                                    sx={{
                                      ...(value.includes(type) && {
                                        backgroundColor: '#EBEBEB',
                                        color: '#00000',
                                        '&:hover': {
                                          backgroundColor: '#cecece',
                                        },
                                      }),
                                    }}
                                  />
                                ))}
                              </Box>
                              {fieldState.error && (
                                <Typography color="error" variant="body2">
                                  {fieldState.error.message}
                                </Typography>
                              )}
                            </>
                          );
                        }}
                      />
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        sx={{ margin: '1rem auto', fontSize: { xs: '0.875rem', sm: '1rem' } }}
                        fullWidth
                        loading={isLoading}
                        className='bg-primary hover:bg-primary-hover'
                      >
                        {t('common:send')}
                      </LoadingButton>
                    </>
                  )}
                </form>
              </div>
            ))}
            {currentStep > 0 && (
              <Typography
                variant='body2'
                className='cursor-pointer flex items-center text-text-secondary font-bold mt-4 sm:mt-8 text-sm sm:text-base'
                onClick={previousStep}
              >
                <ArrowBackIcon fontSize='small' className='mr-1' />
                {t('welcome:back')}
              </Typography>
            )}

            <div className='flex justify-center mt-4'>
              {steps.map((_, index) => (
                <div
                  key={index}
                  className={`w-1.5 h-1.5 sm:w-2 sm:h-2 rounded-full mx-0.5 sm:mx-1 ${index === currentStep ? 'bg-primary' : 'bg-text-tertiary'}`}
                />
              ))}
            </div>
          </div>
        </Box>
      ) : (
        <div className='w-full h-screen flex flex-col justify-center items-center gap-4 px-2 sm:px-0 max-w-md w-full'>
          <Logo className='w-14 h-14 mb-2' />
          <Typography variant='h4' className='w-full text-text-primary text-xl font-bold text-center'>
            {t('welcome:thanks_welcome')}<strong className='text-primary'>{t('welcome:pinner')}</strong>{t('welcome:board')}
          </Typography>
          <Typography variant='body1' className='w-full text-text-secondary text-lg text-center'>
            {t('welcome:welcome_email_subtitle')}<strong className='text-text-primary'>{t('welcome:welcome_email_subtitle_end')}</strong>
          </Typography>
          <Typography variant='body1' className='text-text-secondary text-md text-center'>
            {t('welcome:close_page')}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default WelcomePage