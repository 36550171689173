import React, { useContext, useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../enums/Approutes';
import { AuthContext } from './authContext';
import LoaderGif from '../assets/gifs/loader.gif'

const AuthLoader: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const publicRoutes = [
    AppRoutes.LOGIN,
    AppRoutes.RESET_PASSWORD,
    AppRoutes.SET_NEW_PASSWORD,
    AppRoutes.WELCOME,
    AppRoutes.ON_BOARDING,
    AppRoutes.PROFILE,
  ];

  const isPublicRoute = publicRoutes.some((path) =>
    matchPath({ path, end: true }, location.pathname)
  );

  useEffect(() => {
    if (isAuthenticated === false && !isPublicRoute) {
      navigate(AppRoutes.LOGIN);
    }
  }, [isAuthenticated, isPublicRoute, navigate]);

  if (isAuthenticated === null) {
    return <div className='w-screen h-screen flex justify-center items-center'>
      <img src={LoaderGif} alt='' className='w-16 h-auto object-cover' />
    </div>;
  }

  return <>{children}</>;
};

export default AuthLoader;
