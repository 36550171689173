import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { GoogleProp } from '../../../types/google';
import { toast } from "react-toastify";
import { useContext, useState } from 'react';
import { AuthContext } from '../../../hooks/authContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../enums/Approutes';
import { LoadingButton } from '@mui/lab'
import GoogleIcon from '@mui/icons-material/Google';

const url = process.env.REACT_APP_BASE_URL;

const GoogleButton: React.FC<GoogleProp> = ({ text }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const { login } = useContext(AuthContext);

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse: any) => {
            try {
                setLoading(true);
                const { access_token } = tokenResponse;
                if (!access_token) {
                    throw new Error('Google token is missing');
                }
                const response = await axios.post(`${url}/users/signup_with_google/`, { access_token: access_token });
                const { token } = response.data;
                const responseUser = await axios.get(`${url}/users/auth/profile/`, { headers: { Authorization: `Bearer ${token.access}` } });
                const user_data = responseUser.data;
                login(token.access, user_data.id);

                if (user_data.birth_date) {
                    setLoading(false);
                    navigate(AppRoutes.PIN_MAP);
                } else {
                    setLoading(false);
                    navigate(AppRoutes.WELCOME, { state: { first_name: user_data.first_name, last_name: user_data.last_name, email: user_data.email } });
                }
            } catch (error) {
                console.error(error);
                toast.error(t('toast:google_error'), {
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                setLoading(false);
            }
        },
        onError: () => {
            toast.error(t('toast:google_error'), {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        }
    });

    return (
        <LoadingButton
            loading={loading}
            onClick={() => handleGoogleLogin()}
            fullWidth
            startIcon={
                <GoogleIcon sx={{ color: '#E9495A' }} />
            }
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'trasparent',
                color: '#1A1A1A',
                border: '0.08rem solid #E0E0E0',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: { xs: '0.875rem', sm: '0.9rem' },
                '&:hover': {
                    backgroundColor: 'white',
                    border: '0.08rem solid #E9495A'
                }
            }}
        >
            {text}
        </LoadingButton>
    );
};

export default GoogleButton;
