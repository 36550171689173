import { useState } from 'react';
import { MapStyle } from '../../../types/map';

const StylesControl: React.FC<{ onChange: (style: MapStyle) => void }> = ({ onChange }) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  const styles: MapStyle[] = [
    {
      label: 'Light',
      styleName: 'Mapbox Light',
      styleUrl: 'mapbox://styles/mapbox/light-v9'
    },
    {
      label: 'Streets',
      styleName: 'Mapbox Streets',
      styleUrl: 'mapbox://styles/mapbox/streets-v9',
    }
  ];

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div
      className="mapboxgl-ctrl mapboxgl-ctrl-group"
      style={{
        backgroundColor: 'white',
        padding: '5px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      {styles.map((style) => (
        <button
          key={style.styleUrl}
          className="mapboxgl-ctrl-icon"
          style={{
            display: 'block',
            margin: '5px 0',
            padding: '5px',
            width: '100%',
            backgroundColor: isHover ? 'trasparent' : 'white'
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => onChange(style)}
        >
          {style.label}
        </button>
      ))}
    </div>
  );
};

export default StylesControl