import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from 'react-router-dom'
import { AppRoutes } from '../../enums/Approutes'
import { useEffect, useState } from 'react'
import axios from 'axios'

const url = process.env.REACT_APP_BASE_URL;

const OnBoarding = () => {
    const navigate = useNavigate();
    const { userId, authToken } = useParams();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);
        const activateUser = async () => {
            if (userId && authToken) {
                const response = await axios.put(`${url}/users/activate/`, {
                    "uidb64": userId,
                    "token": authToken
                });
                if (response) {
                    navigate(AppRoutes.LOGIN)
                }
            }
            setLoading(false);
        };

        activateUser();
    }, [userId, authToken, navigate]);

    return (
        <div className='w-full h-screen flex justify-center items-center'>
            <Box component={'div'} className='w-full h-full flex flex-col justify-center gap-16 items-center px-4 sm:px-0 max-w-md w-full'>
                {loading && <CircularProgress color='error' />}
            </Box>
        </div>
    );
};

export default OnBoarding;