import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Box, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DefaultMarkerModalProps } from '../../../interfaces/DefaultMarker';
import CloseIcon from '@mui/icons-material/Close';
import DMarker from '../../../assets/gifs/defaultMarker.gif'

const DefaultMarkerModal: React.FC<DefaultMarkerModalProps> = ({ open, onClose, place, text }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ maxWidth: '100%', overflowX: 'hidden' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
          {/* Place */}
          <div className='w-full text-center'>
            <Typography variant="h4" sx={{ color: '#101828', fontWeight: 'bold' }}>
              {place}
            </Typography>
          </div>
          {/* Lottie Animation */}
          <div className='w-full text-center'>
            <img src={DMarker} alt='' style={{ width: '15rem', height: 'auto' }} />
          </div>
          {/* Knowledge Level */}
          <div className='w-full text-left'>
            <Typography variant="body1" sx={{ color: '#101828', fontWeight: 'medium' }}>
              {text ? text : t('pinMap:default_marker_message')}
            </Typography>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DefaultMarkerModal;
