import { Modal, Box, Typography, Button } from '@mui/material';
import { Facebook, WhatsApp } from '@mui/icons-material';
import XIcon from '@mui/icons-material/X';
import { ShareMapModalProps } from '../../../interfaces/ShareMap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ModalShareMap: React.FC<ShareMapModalProps> = ({ isOpen, onClose, link, image }) => {
  const { t } = useTranslation();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    onClose();
    toast.success(t('pinMap:share_modal_success'), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="share-map-modal"
    >
      <Box className="share-modal" sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '90%', sm: 400, md: 450 },
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '1rem'
      }}>
        <Typography id="share-map-modal" variant="h6" component="h2" sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
          {t('pinMap:share_modal_title')}
        </Typography>

        <div className="social-icons" style={{ display: 'flex', justifyContent: 'space-around', marginTop: '1rem' }}>
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${link}`} target="_blank" rel="noopener noreferrer">
            <Facebook fontSize="large" color='primary' />
          </a>
          <a href={`https://twitter.com/share?url=${link}&text=Condividi la mia mappa`} target="_blank" rel="noopener noreferrer">
            <XIcon fontSize="large" color='inherit' />
          </a>
          <a href={`https://wa.me/?text=${link}`} target="_blank" rel="noopener noreferrer">
            <WhatsApp fontSize="large" color='success' />
          </a>
        </div>
        <Box mt={2}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: { xs: 2, sm: 0 }
          }}>
          <Typography variant="body1" component="p" className='outline-none overflow-hidden mr-2'>
            {link}
          </Typography>
          <Button variant="contained" color='error' onClick={copyToClipboard}>
            {t('pinMap:share_modal_button')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalShareMap;
