import { createContext, useContext, useEffect, useState, ReactNode, useMemo, useCallback } from 'react';
import axios from 'axios';
import { MarkerData } from '../types/markerData';
import { MarkerContextProps } from '../interfaces/ContextMarker';

const url = process.env.REACT_APP_BASE_URL;

const MarkerContext = createContext<MarkerContextProps | undefined>(undefined);

export const MarkerProvider = ({ children }: { children: ReactNode }) => {
    const [markers, setMarkers] = useState<MarkerData[]>([]);
    const userId = sessionStorage.getItem('userId');

    const fetchMarkers = useCallback(async () => {
        try {
            const authToken = sessionStorage.getItem('authToken');
            const headers = {
                'Authorization': `Bearer ${authToken}`
            };
            const response = await axios.get(`${url}/pins/user/${userId}`, { headers });
            setMarkers(response.data);
        } catch (error) {
            console.error('Error fetching markers:', error);
        }
    }, [userId]);

    useEffect(() => {
        fetchMarkers();
    }, [fetchMarkers]);

    const value = useMemo(() => ({ markers, fetchMarkers }), [markers, fetchMarkers]);

    return (
        <MarkerContext.Provider value={value}>
            {children}
        </MarkerContext.Provider>
    );
};

export const useMarkers = () => {
    const context = useContext(MarkerContext);
    if (!context) {
        throw new Error('useMarkers must be used within a MarkerProvider');
    }
    return context;
};
