import React, { useEffect, useState } from 'react';
import { Button, Divider, IconButton, List, ListItem, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { Notification } from '../../interfaces/Notification';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import EmptyState from '../../assets/gifs/empty_state_not.gif'

const url = process.env.REACT_APP_BASE_URL;

const Notifications: React.FC = () => {
    const authToken = sessionStorage.getItem('authToken');
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get(`${url}/auth/profile/notifications/`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                });
                setNotifications(response.data.results);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        fetchNotifications();
    }, [authToken]);

    const markAllAsRead = async () => {
        try {
            await axios.post(`${url}/auth/profile/notifications/read-all/`, {}, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            const updatedNotifications = notifications.map((n) => ({ ...n, read: new Date().toISOString() }));
            setNotifications(updatedNotifications);
        } catch (error) {
            console.error('Errore durante la marcatura di tutte le notifiche come lette:', error);
        }
    };

    const toggleReadStatus = async (id: number, isRead: boolean) => {
        try {
            if (isRead) {
                await axios.post(`${url}/auth/profile/notifications/${id}/unread/`, {}, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                const updatedNotifications = notifications.map((n) =>
                    n.id === id ? { ...n, read: null } : n
                );
                setNotifications(updatedNotifications);
            } else {
                await axios.post(`${url}/auth/profile/notifications/${id}/read/`, {}, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                const updatedNotifications = notifications.map((n) =>
                    n.id === id ? { ...n, read: new Date().toISOString() } : n
                );
                setNotifications(updatedNotifications);
            }
        } catch (error) {
            console.error('Errore durante la modifica dello stato di lettura della notifica:', error);
        }
    };

    const getRelativeTime = (date: any) => {
        const now = new Date();
        const notificationDate = new Date(date);
        const diff = now.getTime() - notificationDate.getTime();
        const diffMinutes = Math.floor(diff / (1000 * 60));
        if (diffMinutes < 60) {
            return `${diffMinutes} minute${diffMinutes !== 1 ? 's' : ''} ago`;
        }
        const diffHours = Math.floor(diffMinutes / 60);
        if (diffHours < 24) {
            return `${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;
        }
        const diffDays = Math.floor(diffHours / 24);
        if (diffDays < 7) {
            return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
        }
        return notificationDate.toLocaleDateString();
    }

    const hasUnreadNotifications = notifications.some((notification) => notification.read === null);

    return (
        <div className="flex flex-col w-full min-h-screen p-6 md:p-28">
            <div className="flex items-center justify-between mb-4 h-10 md:mb-6">
                <div className="flex items-center">
                    <Typography variant="h6" className='font-medium'>{t('notification:title')}</Typography>
                </div>
                {(hasUnreadNotifications &&
                    <Button
                        variant="text"
                        sx={{
                            color: '#606060',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            textDecoration: 'underline',
                            '&:hover': {
                                backgroundColor: 'transparent',
                            }
                        }}
                        onClick={markAllAsRead}
                    >
                        {t('notification:mark_all_as_read')}
                    </Button>
                )}
            </div>

            <Divider className='mb-4 md:mb-6' />

            {notifications.length > 0 ? (
                <List className='w-full flex flex-col gap-6 p-0'>
                    {notifications.map((notification) => (
                        <ListItem
                            onClick={() => {
                                toggleReadStatus(notification.id, notification.read !== null);
                            }}
                            key={notification.id}
                            className="w-full flex p-3 cursor-pointer"
                            sx={{
                                flexWrap: 'nowrap',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderRadius: '0.6rem',
                                '&:hover': {
                                    backgroundColor: '#F3F3F3',
                                },
                            }}
                        >
                            <div className="flex flex-col w-[85%] max-w-[85%] md:w-[80%] md:max-w-[80%] h-auto">
                                <Typography
                                    className='text-sm whitespace-normal'
                                    style={{
                                        color: '#606060',
                                        fontWeight: notification.read === null ? 'bold' : 'normal',
                                    }}
                                >
                                    {notification.text}
                                </Typography>
                                <Typography variant="body2" className="text-text-tertiary text-xs">
                                    {getRelativeTime(notification.send_date)}
                                </Typography>
                            </div>
                            <IconButton
                                size="small"
                                sx={{ marginLeft: '0.5rem' }}
                            >
                                <CircleIcon
                                    fontSize="small"
                                    sx={{
                                        color: notification.read === null ? '#E9495A' : 'transparent',
                                        border: notification.read !== null ? '1px solid #9CA3AF' : 'none',
                                        borderRadius: '50%',
                                        width: '1rem',
                                        height: '1rem'
                                    }}
                                />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            ) : (
                <div className='flex flex-col items-center text-center m-auto'>
                    <p className="text-md text-muted-foreground mb-4">{t('notification:no_data')}</p>
                    <img src={EmptyState} alt='Empty state' style={{ width: '12rem', height: 'auto', objectFit: 'cover' }} />
                </div>
            )}
        </div>
    );
};

export default Notifications;
