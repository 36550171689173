import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Typography, Box, InputAdornment, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import { SetNewPasswordType } from '../../types/setNewPassword';
import { AppRoutes } from '../../enums/Approutes';
import { LockIcon, CheckIcon } from '../../assets/icons/Index';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const url = process.env.REACT_APP_BASE_URL;

const SetNewPassword = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const { control, handleSubmit, watch } = useForm<SetNewPasswordType>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { userId, authToken } = useParams();
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    specialChar: false,
  });

  const password = watch('password');
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    if (password) {
      setPasswordCriteria({
        length: password.length >= 8,
        specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      });
    }
  }, [password]);

  const onSubmit = async (data: SetNewPasswordType) => {
    try {
      setLoading(true);
      const response = await axios.put(`${url}/users/set_new_password/`,
        {
          "uidb64": userId,
          "token": authToken,
          "password": data.password,
          "confirm_password": data.confirmPassword,
        }
      );
      if (response) {
        setStep(2);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data', error);
      toast.error(t('toast:set_new_password_error'), {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored'
      });
      setLoading(false);
    }
  };

  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <Box component={"div"} className="flex flex-col justify-center items-center px-4 sm:px-0 max-w-md w-full gap-4">
        {step === 1 && (
          <>
            <LockIcon className='w-14 h-14' />
            <Typography variant="h4" className="text-text-primary text-2xl font-bold text-center">
              {t('setNewPassword:title')}
            </Typography>
            <Typography variant='body1' className='text-text-secondary text-base text-center'>
              {t('setNewPassword:subtitle')}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-md">
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: t('setNewPassword:password_required'),
                  minLength: { value: 8, message: t('setNewPassword:password_min_length') },
                  pattern: { value: /[!@#$%^&*(),.?":{}|<>]/, message: t('setNewPassword:password_special_char') }
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!error}
                    helperText={error?.message}
                    slotProps={{
                      inputLabel: { shrink: true },
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#E0E0E0',
                        },
                        '&:hover fieldset': {
                          borderColor: '#E0E0E0',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#E0E0E0',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#000000',
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#000000',
                      },
                      '& .MuiInputBase-input.Mui-disabled': {
                        color: '#000000',
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                rules={{
                  required: t('setNewPassword:confirm_password_required'),
                  validate: (value) => value === watch('password') || t('setNewPassword:confirm_password_match')
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type={showPassword ? 'text' : 'password'}
                    label={t('setNewPassword:confirm_password_required')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!error}
                    helperText={error?.message}
                    slotProps={{
                      inputLabel: { shrink: true },
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#E0E0E0',
                        },
                        '&:hover fieldset': {
                          borderColor: '#E0E0E0',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#E0E0E0',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#000000',
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#000000',
                      },
                      '& .MuiInputBase-input.Mui-disabled': {
                        color: '#000000',
                      },
                    }}
                  />
                )}
              />
              <Box className="mt-2 mb-4">
                <Typography variant="body2" className="flex items-center">
                  <CheckCircleRoundedIcon color={passwordCriteria.length ? 'success' : 'disabled'} className="mr-2" />
                  {t('setNewPassword:password_min_length')}
                </Typography>
                <Typography variant="body2" className="flex items-center">
                  <CheckCircleRoundedIcon color={passwordCriteria.specialChar ? 'success' : 'disabled'} className="mr-2" />
                  {t('setNewPassword:password_special_char')}
                </Typography>
              </Box>
              <LoadingButton
                type="submit"
                variant="contained"
                fullWidth
                loading={loading}
                className="mt-4 bg-primary hover:bg-primary-hover"
              >
                {t('setNewPassword:button')}
              </LoadingButton>
            </form>
            <Typography
              variant='body2'
              className='cursor-pointer flex items-center text-text-secondary font-bold mt-4'
              onClick={() => navigate('/login')}
            >
              <ArrowBackIcon fontSize='small' className='mr-1' />
              {t('setNewPassword:back')}
            </Typography>
          </>
        )}
        {step === 2 && (
          <>
            <CheckIcon className='w-20 h-20 success' />
            <Typography variant='h4' className='text-text-primary text-xl font-medium text-center'>
              {t('setNewPassword:success_title')}
            </Typography>
            <Typography variant='body1' className='text-text-secondary text-base text-center'>
              {t('setNewPassword:success_subtitle')}
            </Typography>
            <Button variant="contained" className='bg-primary hover:bg-primary-hover mt-4' fullWidth onClick={() => navigate(AppRoutes.LOGIN)}>
              {t('setNewPassword:back')}
            </Button>
          </>
        )}
      </Box>
    </div>
  );
};

export default SetNewPassword;