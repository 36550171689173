import React from 'react'

const Feedback = () => {
    return (
        <div className='w-full h-screen flex justify-center items-center py-12 px-4'>
            <iframe title='productLift' src="https://feedback.pinnalo.it/t/wishlist?widget_id=11d74b56-d998-4f77-9fb2-e5552f707c28" data-productlift-link width="100%" height="100%" allowFullScreen style={{ overflow: 'hidden', margin: 0 }}></iframe>
        </div>
    )
}

export default Feedback;