import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import {
  DeleteUserModalProps,
  ReasonProp,
} from "../../../interfaces/DeleteAccount";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { toast } from "react-toastify";
import DeleteUser from "../../../assets/gifs/deleteUser.gif";
import { useForm, Controller } from "react-hook-form";

const url = process.env.REACT_APP_BASE_URL;

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ReasonProp>();

  useEffect(() => {
    if (!open) {
      reset();
      setCurrentStep(0);
    }
  }, [open, reset]);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const onSubmit = async (data: ReasonProp) => {
    setLoading(true);

    try {
      const authToken = sessionStorage.getItem("authToken");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const body = {
        reason: data.reason,
      };

      const getUserData = await axios.get(`${url}/users/auth/profile/`, {
        headers,
      });
      const userData = getUserData.data;

      const response = await axios.delete(`${url}/users/auth/profile/`, {
        headers,
        data: body,
      });

      if (response) {
        const brevoOptions = {
          method: "DELETE",
          headers: {
            accept: "application/json",
            "api-key":
              "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",
          },
        };

        fetch(
          `https://api.brevo.com/v3/contacts/${userData.email}`,
          brevoOptions
        )
          .then((res) => res.json())
          .then((res) => console.log(res))
          .catch((err) => console.error(err));

        setLoading(false);
        toast.success(t("toast:delete_user_success"), {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        sessionStorage.removeItem("userId");
        sessionStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        window.location.href = "https://www.pinnalo.it";
      }
    } catch (error) {
      console.error("Errore nell'eliminazione dell'account:", error);
      toast.error(t("toast:delete_user_error"), {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {currentStep === 0
          ? t("settings:delete_account_title")
          : t("settings:delete_account_next_title")}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {currentStep === 0 ? (
        <>
          <DialogContent sx={{ maxWidth: "100%", overflowX: "hidden" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 4,
              }}
            >
              <img
                src={DeleteUser}
                alt=""
                style={{ width: "18rem", height: "auto", objectFit: "cover" }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              variant="outlined"
              fullWidth
              sx={{
                margin: "1rem",
                fontSize: { xs: "0.875rem", sm: "1rem" },
                backgroundColor: "transparent",
                color: "#475467",
                border: "1px solid #667085",
              }}
            >
              {t("common:exit")}
            </Button>
            <LoadingButton
              loading={loading}
              onClick={nextStep}
              variant="contained"
              fullWidth
              sx={{
                margin: "1rem",
                fontSize: { xs: "0.875rem", sm: "1rem" },
                backgroundColor: "#E9495A",
                color: "white",
                "&hover": {
                  backgroundColor: "#E6263B",
                },
              }}
            >
              {t("common:next")}
            </LoadingButton>
          </DialogActions>
        </>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={{ maxWidth: "100%", overflowX: "hidden" }}>
            <Box sx={{ width: "100%" }}>
              <Controller
                name="reason"
                control={control}
                defaultValue=""
                rules={{
                  required: t("settings:reason_required"),
                  minLength: 10,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    error={Boolean(errors.reason)}
                    helperText={errors.reason?.message}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#E0E0E0",
                        },
                        "&:hover fieldset": {
                          borderColor: "#E0E0E0",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#E0E0E0",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#000000",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#000000",
                      },
                      "& .MuiInputBase-input.Mui-disabled": {
                        color: "#000000",
                      },
                    }}
                  />
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                margin: "1rem",
                fontSize: { xs: "0.875rem", sm: "1rem" },
                backgroundColor: "#E9495A",
                color: "#FFFFF",
                "&hover": {
                  backgroundColor: "#E6263B",
                },
              }}
            >
              {t("settings:delete_account_confirm")}
            </LoadingButton>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

export default DeleteUserModal;
